::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-track {
  background: #efefef;
}

::-webkit-scrollbar-thumb {
  background: #403e3e;
  border-radius: 0;
}

::-webkit-scrollbar-thumb:hover {
  background: #fd6308;
  cursor: pointer;
}

::-webkit-scrollbar-thumb:active {
  background: #fd6308;
}

body {
  font-family: "Manrope", sans-serif !important;
  font-size: 14px;
  line-height: 24px;
  color: #6f6f6f;
}

.top-lines a {
  padding: 0px 5px;
  color: #1b1464;
}

.top-lines {
  background: #ff6600;
  color: #403e3e;
  float: left;
  width: 100%;
  padding: 5px 0px;
  margin: 0px;
}

.top-lines .text-left {
  text-align: left;
}

.top-lines .text-right {
  text-align: right;
}

.top-menu {
  background: #fff;
  float: left;
  width: 100%;
  padding: 0px 0px;
  margin: 0px;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99999999;
}

.privacy-banner {
  float: left;
  width: 100%;
  padding: 0px 0px;
  margin: 0px;
}

.innrer-banner {
  float: left;
  width: 100%;
  padding: 0px 0px;
  margin: 0px;
}

.innrer-banner h1 {
  font-size: 38px;
  color: #1b1464;
  margin-bottom: 21px;
  line-height: 29px;
  text-align: center;
}

.innrer-banner h3 {
  font-size: 16px;
  font-weight: bold;
  color: #000 !important;
  text-align: center;
}

.innrer-banner a {
  color: #000;
}

.innrer-banner a:hover {
  color: #fff;
  text-decoration: blink;
}

.top-banner {
  background: url(../images/home.jpg) center;
  float: left;
  width: 100%;
  padding: 145px 0px;
  margin: 0px;
  background-size: cover;
}

.top-banner h2 {
  font-size: 71px;
  color: #fff;
  margin-bottom: 21px;
  line-height: 76px;
}

.top-banner h5 {
  margin-top: 56px;
  color: #fff;
}

.top-banner h4 {
  color: #f77f00;
  font-size: 24px;
  margin-bottom: 30px;
}

.submit-buttons {
  background: #1b1464 !important;
  color: #fff !important;
  padding: 8px 44px !important;
  font-size: 20px !important;
  outline: none !important;
}

.top-pay {
  background: #fff;
  padding: 40px 35px !important;
}

.top-pay h1 {
  text-align: center;

  font-size: 30px;

  line-height: 35px;

  color: #000;
}

.red-clor {
  color: red;
}

.top-pay p {
  text-align: center;

  font-size: 15px;
}

.top-pay input {
  border: solid 1px #ddd;
  padding: 12px;
  height: 45px;
}

.top-pay .btn {
  background: #3e467b;
  color: #fff;
  font-size: 18px;
  padding: 10px 40px;
  border-radius: 50px;
  border: none;
}

.top-pay .btn:hover {
  background: #cddc39;
  color: #fff;
}

.buttona {
  float: left;
  background: #f77f00;
  padding: 10px 40px;
  border-radius: 50px;
  font-size: 16px;
  color: #fff;
  font-weight: bold;
  margin-right: 27px;
}

.buttona:hover {
  background: #3e467b;
  color: #fff !important;
  text-decoration: blink;
}

.buttonb {
  background: #1b1464;
  float: left;
  padding: 10px 40px;
  border-radius: 50px;
  font-size: 16px;
  color: #fff;
  font-weight: bold;
  margin-right: 27px;
}

.buttonb:hover {
  background: #e5f44e;
  color: #fff;
  text-decoration: blink;
}

.india-kart span {
  font-size: 12px;
}

.india-kart {}

.india-kart input {
  border-radius: 10px;
  height: 47px;
  border: none;
  box-shadow: 0 0 4px #ddd;
  font-size: 13px;
  margin-bottom: 3px;
}

.india-kart select {
  border-radius: 10px;
  height: 47px;
  border: none;
  box-shadow: 0 0 4px #ddd;
  font-size: 13px;
  margin-bottom: 3px;
}

.contact-line {
  width: 100%;
  float: left;
  padding: 55px 0;
  margin: 0;
  background: #fff;
}

.contact-line .thumbnail {
  padding: 30px;
  border-radius: 0;
  border: solid 1px #ddd;
  height: auto;
  text-align: left;
  height: 253px;
}

.contact-line .thumbnail .fa {
  font-size: 31px;
  border: solid 2px #0363a3;
  padding: 18px;
  border-radius: 100%;
  height: 71px;
  color: #0363a3 !important;
  width: 71px;
}

.contact-line .thumbnail .tasgs {
  font-size: 18px !important;
  font-weight: bold;
  color: #1b1464;
}

.contact-line h2 {
  color: #1b1464;
  margin: 0 0 -3px !important;
  font-size: 25px;
}

.input-kart {
  height: 45px !important;
  border: solid 1px #ddd !important;
  margin-bottom: 15px;
  color: #8c8c8c;
}

.hr-calculateor {
  width: 60px;
  border: none !important;
  margin: 2px 0;
  height: 3px;
  background: #1b1464;
  margin-bottom: 13px;
}

.india-kart p {
  font-size: 14px !important;
  margin-top: 15px;
}

.india-kart .col-lg-6 {
  float: left;
  width: 50% !important;
}

.india-kart .col-lg-12 {
  float: left;
  width: 100% !important;
}

.footer-links {
  padding: 14px;
  color: #fff;
}

.footer-links:hover {
  text-decoration: blink;
  color: #fff;
}

.about-are {
  background: #fff;
  float: left;
  width: 100%;
  padding: 50px 0px;
  margin: 0px;
  text-align: justify;
}

.about-are h3 {
  font-size: 30px;
  margin-bottom: 2px;
  font-weight: bold;
  color: #000;
}

/* .apply-now-button {
  background: #1b1464 !important;
  color: #fff !important;
  padding: 12px 45px !important;
  border: none !important;
  font-size: 15px !important;
  outline: none !important;
} */

.about-are h2 {
  font-size: 30px;
  margin-bottom: 2px;
  font-weight: bold;
  color: #000;
  margin-bottom: 30px;
}

.about-are .thumbnail {
  padding: 20px;
  border: solid 2px #ddd;
}

.about-are p {
  font-size: 16px;
  text-align: justify;
  color: #000;
}

.where-are .thumbnail {
  border: none !important;
  box-shadow: 0 0 10px #ddd;
  padding: 20px;
}

.where-are {
  background: #fff;
  float: left;
  width: 100%;
  padding: 50px 0px;
  margin: 0px;
  text-align: center;
}

.where-are h3 {
  font-size: 21px;
  margin-bottom: 2px;
}

.where-are p {}

.where-are a {
  color: #3e467b;
  font-weight: bold;
}

.where-are a:hover {
  color: #3e467b;
  text-decoration: blink;
  font-weight: bold;
}

.Get-money {
  background: url(../images/blue-poly-background.jpg) center;
  float: left;
  width: 100%;
  padding: 50px 0px;
  margin: 0px;
  text-align: left;
}

.Get-money h5 {
  font-size: 35px;
  color: #fff;
  line-height: 44px;
  margin-top: 32px;
}

.Get-money h4 {
  text-align: center;
  color: #f77f00;
  font-size: 16px;
}

.Get-money h3 {
  text-align: center;
  color: #fff;
  font-size: 35px;
}

.Get-money p {
  color: #fff;
  font-size: 15px;
}

.Get-manager {
  background: #fff;
  float: left;
  width: 100%;
  padding: 79px 0px;
  margin: 0px;
  text-align: left;
}

.Get-manager h5 {
  font-size: 35px;
  color: #000;
  line-height: 44px;
}

.Get-manager h4 {
  text-align: center;
  color: #cddc39;
  font-size: 16px;
}

.Get-manager h3 {
  text-align: center;
  color: #fff;
  font-size: 35px;
}

.Get-manager p {
  color: #000;
  font-size: 15px;
}

.PayDay {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 100px 0px;
  text-align: center;
  background: #fff;
}

.PayDay .thumbnail {
  text-align: center;
  height: 179px;
  border-radius: 0px;
  box-shadow: 0 0 5px #e7e7e7;
  border: none !important;
  padding: 30px;
}

.PayDay h3 {
  color: #3e467b;
  font-size: 34px;
  margin-bottom: 40px;
  text-align: center;
}

.PayDay h2 {
  font-size: 20px;
  margin-bottom: 0px;
  line-height: 31px;
}

.PayDay p {
  font-size: 16px;
}

.PayDay img {
  width: 30px;
  margin-top: 11px;
}

.decision {
  width: 100%;
  float: left;
  background: url(../images/home-background-1.jpg) center;
  padding: 100px 0px;
  color: #fff;
}

.decision h3 {
  font-size: 40px;
  color: #fff;
}

.decision h4 {
  color: #f77f00;
}

.decision h5 {
  color: #fff;
  font-weight: 900;
  font-size: 77px;
}

.decision p {
  color: #fff;
  font-size: 22px;
  margin-top: 36px;
}

.latest-news {
  float: left;
  width: 100%;
  background: #fff;
  padding: 60px 0px;
}

.latest-news h3 {
  color: #3e467b;
  font-size: 34px;
  margin-bottom: 40px;
  text-align: center;
}

.post-slide {
  padding-bottom: 10px;
  margin: 0 15px;
  position: relative;
  border: solid 1px #ddd;
}

.post-slide img {
  width: 100%;
  height: auto;
}

.post-slide .post-date {
  position: absolute;
  top: 2%;
  left: 8%;
  padding: 3% 5%;
  background: #1b1464;
}

.post-slide .month {
  font-size: 14px;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
}

.post-slide .month:after {
  content: "";
  display: block;
  border: 1px solid #fff;
}

.post-slide .date {
  font-size: 14px;
  color: #fff;
  display: block;
  text-align: center;
  font-weight: bold;
}

.post-slide .post-title {
  margin: 0px;
  padding: 20px;
  line-height: 22px;
}

.post-slide .post-title a {
  font-size: 15px;
  font-weight: bold;
  color: #333;
  display: inline-block;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
}

.post-slide .post-title a:hover {
  text-decoration: none;
  color: #e74c3c;
}

.post-slide .post-description {
  font-size: 14px;
  line-height: 24px;
  color: #808080;
}

.post-slide .read-more {
  color: #333;
  text-transform: capitalize;
  transition: color 0.2s linear;
}

.post-slide .read-more i {
  margin-left: 10px;
  font-size: 10px;
}

.post-slide .read-more:hover {
  text-decoration: none;
  color: #e74c3c;
}

.owl-controls .owl-buttons {
  margin-top: 20px;
  position: relative;
}

.owl-controls .owl-prev {
  position: absolute;
  left: -2px;
  bottom: 122px;
  padding: 8px 17px;
  background: #333;
  transition: background 0.5s ease;
}

.owl-controls .owl-next {
  position: absolute;
  right: -4px;
  bottom: 122px;
  padding: 8px 17px;
  background: #333;
  transition: background 0.5s ease;
}

.owl-controls .owl-prev:after,
.owl-controls .owl-next:after {
  content: "\f104";
  font-family: FontAwesome;
  color: #d3d3d3;
  font-size: 16px;
}

.owl-controls .owl-next:after {
  content: "\f105";
}

.owl-controls .owl-prev:hover,
.owl-controls .owl-next:hover {
  background: #e74c3c;
}

@media only screen and (max-width: 990px) {
  .post-slide {
    margin: 0 20px;
  }

  .owl-controls .owl-buttons .owl-prev {
    left: -20px;
    padding: 5px 14px;
  }

  .owl-controls .owl-buttons .owl-next {
    right: -20px;
    padding: 5px 14px;
  }
}

@media only screen and (max-width: 767px) {
  .owl-controls .owl-buttons .owl-prev {
    left: 0px;
    bottom: 260px;
  }

  .owl-controls .owl-buttons .owl-next {
    right: 0px;
    bottom: 260px;
  }
}

.cmsmasters-line {
  float: left;
  width: 100%;
  background: url(../images/home-testimonials1.jpg) center fixed;
  padding: 100px 0px;
}

.cmsmasters-line h3 {
  text-align: center;

  color: #fff;

  font-size: 34px;
}

.cmsmasters-line h4 {
  text-align: center;

  font-size: 20px;

  color: #f77f00;
}

.testimonial {
  text-align: center;
}

.testimonial .pic {
  width: 85px;
  height: 85px;
  border-radius: 50%;
  margin: 0 auto 40px;
  border: 4px solid #f77f00;
  overflow: hidden;
}

.testimonial .pic img {
  width: 100%;
  height: auto;
}

.testimonial .description {
  color: #fff;
  font-size: 25px;
  font-style: italic;
  line-height: 37px;
  margin-bottom: 20px;
  padding: 40px;
}

.testimonial .testimonial-prof {
  margin: 20px 0;
}

.testimonial .title {
  font-size: 20px;
  color: #f77f00;
  margin-right: 20px;
  text-transform: capitalize;
}

.testimonial .title:after {
  content: "";
  margin-left: 30px;
  border-right: 1px solid #808080;
}

.testimonial .testimonial-prof small {
  display: inline-block;
  color: #8a9aad;
  font-size: 17px;
  text-transform: capitalize;
}

.owl-theme .owl-controls .owl-buttons div {
  background: #e74c3c;
  opacity: 1;
}

.owl-buttons {
  position: absolute;
  top: 8%;
  width: 100%;
}

.owl-prev {
  position: absolute;
  left: 30%;
}

.owl-next {
  position: absolute;
  right: 30%;
}

.owl-prev:after,
.owl-next:after {
  content: "\f060";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  width: 28px;
  height: 28px;
  font-size: 16px;
  color: #808080;
  transition: all 0.15s ease 0s;
}

.owl-next:after {
  content: "\f061";
}

@media only screen and (max-width: 479px) {
  .owl-prev {
    left: 10%;
  }

  .owl-next {
    right: 10%;
  }
}

.footer-address {
  background: #2f2f2f;
  padding: 100px 0px;
  float: left;
  width: 100%;
  margin: 0px;
  color: #fff;
}

.footer-line {
  background: #1d1d1d;
  padding: 100px 0px;
}

.footer-cart {
  float: left;
  padding: 35px 0;
  margin: 0;
  width: 100%;
  color: #fff;
  background: #1b1464;
}

.footer-marg {
  margin-bottom: 15px;
}

.fa-links {
  font-size: 16px !important;
  width: 33px;
  height: 33px;
  background: #f77f00;
  float: left;
  margin: 12px 10px 0 0;
  text-align: center;
  line-height: 30px;
  color: #fff;
  border-radius: 100%;
  border: solid 2px #fff;
}

.fa-links:hover {
  background: none;
  border: solid 2px #fff;
  color: #fff;
}

.footer-link {
  color: #f1f1f1;
  line-height: 36px;
  font-size: 14px;
}

.footer-link:hover {
  color: #fff;
  text-decoration: blink;
}

.copyrihgt-footer {
  width: 100%;
  float: left;
  padding: 10px 0;
  margin: 0;
  font-size: 13px;
  color: #fff;
  background: #ff6600;
  text-align: center;
}

.disp {
  display: block;
}

.apply-now {
  background: #f60;
  color: #fff !important;
  text-transform: uppercase;
  font-weight: bold;
  padding: 10px 30px !important;
  border-radius: 5px;
  margin-top: 19px;
}

@media all and (max-width: 320px),
(max-width: 375px),
(max-width: 384px),
(max-width: 414px),
(max-device-width: 450px),
(max-device-width: 480px),
(max-device-width: 540px) {
  .PayDay .thumbnail {
    text-align: center;
    height: auto;
    border-radius: 0px;
    box-shadow: 0 0 5px #e7e7e7;
    border: none !important;
    padding: 30px;
  }

  .apply-now {
    background: #f60;
    margin-right: 10px !important;
    color: #fff !important;
    font-size: 13px !important;
    text-transform: uppercase;
    font-weight: bold;
    padding: 5px 3px !important;
    padding-left: 3px;
    border-radius: 5px;
    margin-top: 11px !important;
    float: right !important;
    width: 100px !important;
    text-align: center;
  }

  .innrer-banner {
    float: left;
    width: 100%;
    padding: 30px 0px;
    margin: 0px;
    background-size: cover !important;
    background: url(../images/about-banner.jpg);
  }

  .innrer-banner h1 {
    font-size: 29px;
    color: #1b1464;
    margin-bottom: 4px;
    line-height: 29px;
    margin-top: 39px;
    text-align: center !important;
  }

  .innrer-banner h3 {
    font-size: 15px;
    color: #1b1464;
    margin-bottom: 21px;
    line-height: 29px;
    text-align: center !important;
  }

  .disp {
    display: none;
  }

  .top-banner {
    background: url(../images/home.jpg) center;
    background-size: auto;
    float: left;
    width: 100%;
    padding: 50px 0px;
    margin: 0px;
    background-size: cover;
  }

  .top-banner h2 {
    font-size: 29px;
    color: #fff;
    margin-bottom: 21px;
    line-height: 34px;
  }

  .top-banner h5 {
    margin-top: 0px;
    color: #fff;
  }

  .top-banner h4 {
    color: #f77f00;
    font-size: 15px;
    margin-bottom: 16px;
  }

  .buttona {
    float: left;
    background: #f77f00;
    padding: 10px;
    border-radius: 50px;
    font-size: 13px;
    color: #fff;
    font-weight: bold;
    margin-right: 27px;
  }

  .buttonb {
    background: #1b1464;
    float: left;
    padding: 10px;
    border-radius: 50px;
    font-size: 12px;
    color: #fff;
    font-weight: bold;
    margin-right: 27px;
  }

  .top-lines .text-left {
    text-align: center !important;
  }

  .top-pay {
    background: #fff;
    padding: 40px 35px !important;
    margin-top: 56px;
  }

  .top-pay h1 {
    text-align: center;
    font-size: 17px;
    line-height: 35px;
    color: #000;
  }

  .Get-money h3 {
    text-align: center;
    color: #fff;
    font-size: 23px;
    margin: 0px;
  }

  .Get-money h4 {
    text-align: center;
    color: #f77f00;
    font-size: 11px;
    margin: 0px;
  }

  .PayDay h2 {
    font-size: 14px;
    margin-bottom: 0px;
    line-height: 22px;
  }

  .PayDay p {
    font-size: 13px;
  }

  .decision h5 {
    color: #fff;
    font-weight: 900;
    font-size: 50px;
  }

  .decision p {
    color: #fff;
    font-size: 15px;
    margin-top: 14px;
    margin-bottom: 35px;
  }

  .decision {
    width: 100%;
    float: left;
    background: url(../images/home-background-1.jpg) center;
    padding: 20px 0px;
    color: #fff;
  }

  .PayDay {
    float: left;
    width: 100%;
    margin: 0px;
    padding: 50px 0px;
    background: #fff;
  }

  .PayDay h3 {
    color: #3e467b;
    font-size: 28px;
    margin-bottom: 40px;
  }

  .Get-money h5 {
    font-size: 28px;
    color: #fff;
    line-height: 35px;
    margin-top: 32px;
  }

  .Get-manager h5 {
    font-size: 28px;
    color: #000;
    line-height: 35px;
  }

  .Get-manager {
    background: #fff;
    float: left;
    width: 100%;
    padding: 50px 0px;
    margin: 0px;
    text-align: left;
  }

  .cmsmasters-line h3 {
    text-align: center;
    color: #fff;
    font-size: 27px;
    line-height: 35px;
  }

  .cmsmasters-line h4 {
    text-align: center;
    font-size: 11px;
    color: #f77f00;
  }

  .testimonial .description {
    color: #fff;
    font-size: 12px;
    font-style: italic;
    line-height: 22px;
    margin-bottom: 20px;
    padding: 13px;
  }
}

.con-tag {
  margin: 0px;
  font-size: 19px;
  font-weight: bold;
  text-transform: uppercase;
  color: #1b1464;
}

.my-fa {
  font-size: 21px !important;
  background: #1b1464;
  padding: 10px;
  float: left;
  width: 57px;
  height: 57px;
  text-align: center;
  margin-bottom: 20px;
  border-radius: 100%;
  line-height: 39px !important;
  color: #fff;
}

.contacts strong {
  font-size: 20px;
}

.contacts {
  background: #ff6600;
  padding: 49px 20px;
  border-radius: 10px;
}

.apply-now:hover {
  text-decoration: blink;
  background: #1b1464 !important;
  color: #fff !important;
}

.active {
  color: #dc3545 !important;
}

a {
  text-decoration: none !important;
}

/* // Thank your */


/* .thank-you-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(90deg, #f0e7de, #f77f00a1);
  height: 100vh;
   This sets the height to fill the full viewport. Adjust as needed.
} */

/* .thank-you-content {
  text-align: center;
  color: #333;
} */

.checkmark-circle {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #4CAF50;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px #4CAF50;
  animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
  margin: 0 auto 20px auto;
}

.checkmark {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 10% auto;
  box-shadow: inset 0px 0px 0px #4CAF50;
  animation: stroke .6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark-check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.6s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {

  0%,
  100% {
    transform: none;
  }

  50% {
    transform: scale (1.1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #4CAF50;
  }
}


.thank-you-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 2rem;
  background: linear-gradient(45deg, #fffbeb, #f3c1c6);
}

.thank-you-header {
  text-align: center;
}



.thank-you-body {
  max-width: 600px;
  margin-top: 0rem;
  background: #fff;
  padding: 2rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
}

.confirmation-details {
  background: #f9f9f9;
  margin: 2rem 0;
  padding: 1rem;
  border-left: 5px solid #4BB543;
}

h1 {
  color: #333;
  /* Adjust color as needed */
  font-size: 2.5rem;
  /* Adjust font size as needed */
}

h2 {
  color: #333;
  /* Adjust color as needed */
  font-size: 1.5rem;
  /* Adjust font size as needed */
}

p {
  color: #666;
  /* Adjust color as needed */
  line-height: 1.6;
  /* Adjust line height as needed */
}

.apply-now-button {
  background-color: #f77f00;
  color: white;
  border: #1f0d87;
  padding: 10px 20px;
  font-size: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* Hover state styling */
.apply-now-button:hover {
  background-color: #1f0d87;
  color: white; /* Ensure text remains white */
  border: #f77f00;
}
